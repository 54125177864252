import 'magnific-popup';
jQuery( document ).ready(function($) {
    $('.apple-btn').magnificPopup({
        items: {
            src: '#apple-popup',
            type: 'inline'
        }
    });

    $('.email-btn').magnificPopup({
        items: {
            src: '#email-popup',
            type: 'inline'
        }
    });

    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();
    
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thank You For Connecting</p>');
                $.magnificPopup.close();
                $('#intro').hide();
                $('#success').show();
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    // let SMEPreSave = {
    //   state: null,
    //   spotify_user: null,
    //   url_vars: [],
    //   getURLVars: function (callback, form_id) {
    //     let hash;
    //     let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    //     for (let i = 0; i < hashes.length; i++) {
    //       hash = hashes[i].split('=');
    //       this.url_vars.push(hash[0]);
    //       this.url_vars[hash[0]] = hash[1];
    //         }
    //     this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
    //     this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
    //     this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
    //     this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
    //     this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
    //     if (typeof callback === 'function') {
    //       callback(this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message);
    //     }
    //   }
    // };
    
    // /* String state
    //   *  The status of the pre-save.
    //   *  Int ae_member_id
    //   *  The Appreciation Engine member ID. This can be passed to the forms processor.
    //   *  String spotify_user
    //   *  An encrypted string identifying the Spotify user. Can be passed to the pre-save forms processor.
    //   *  String deezery_user
    //   *  An encrypted string identifying the Deezer user. Can be passed to the pre-save forms processor.
    //   */
    // SMEPreSave.getURLVars(function (state, ae_member_id, spotify_user, deezer_user, message) {
    //   // Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
    //   if (state === 'success' || state === 'thank-you') {
    //     $.magnificPopup.close();
    //     $('#intro').hide();
    //     $('#success').show();
    //   }
    // });

    // $('.apple-btn').on('click', function(e){
    //     e.preventDefault();
    //     var sm = new SMEAppleMusic({
    //         ae: {
    //           ae: "7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd",
    //           brand_id: "3448657",
    //           segment_id: "1747102",
    //           activities: '{"actions":{"presave":105002},"mailing_list_optins":{"a0S1p00000Q3MGGEA3":105003}}'
    //         },
    //         am: {
    //           dev_token: "",
    //           save_mode: "library",
    //           custom_playlist_name: "",
    //           new_apple_presave: true,
    //           resources: {
    //             playlists: [],
    //             albums: []
    //           }
    //         },
    //         sf: {
    //           form: "252933",
    //           default_mailing_list: ""
    //         },
    //         smf: {
    //           campaign_id: "252933",
    //           campaign_key: "94614b8136b7925c238573a0db1425f0"
    //         }
    //       });
    //       document.getElementById("apple_music_form").addEventListener("submit", function (e) {
    //         e.preventDefault();
    //         // Grab the email address value.
    //         var email = document.getElementById("apple_music_email_address").value;
    //         // Pass the email address and opt in status
    //         // This can be true or false depending on if the user is forced into the list, or chooses via a checkbox or similar.
    //         sm.doActions(email, false, ["a0S1p00000Q3MGGEA3"]).then(function (res) {
    //             // Complete - handle your logic here
    //             $.magnificPopup.close();
    //             $('#intro').hide();
    //             $('#success').show();
    //         }).catch(function (err) {
    //         // Error - handle your logic here
    //         });
    //     });
    // });
});